import { useEffect, useRef, useContext, useState } from 'react';
import * as React from 'react';
import { GlobalContext } from "../../contexts/GlobalContext";

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ApiSavePatient } from '../../utils/RestServices';
import 'moment/locale/en-gb';
import Button from '@mui/material/Button';
import moment from 'moment';

const PatientEdit = ({setMustRefresh, mustRefresh}) => {
    const { currentEntityForEdit, setCurrentEntityForEdit, loggedUser } = useContext(GlobalContext);
    const inputFirstNameRef = useRef();
    const inputLastNameRef = useRef();
    const inputEmailRef = useRef();
    const inputPhoneRef = useRef();
    const inputBirthDateRef = useRef();

    const [gender, setGender] = useState("Male");

    const handleChange = (event) => {
        setGender(event.target.value);
      };
    

    useEffect(() => {
        if (currentEntityForEdit._id) {
            inputFirstNameRef.current.value = currentEntityForEdit.first_name;
            inputLastNameRef.current.value = currentEntityForEdit.last_name;
            inputEmailRef.current.value = currentEntityForEdit.email;
            inputPhoneRef.current.value = currentEntityForEdit.phone;
            setGender(currentEntityForEdit.gender);
        }
    }, [])

    const savePatient = () => {
        currentEntityForEdit.first_name = inputFirstNameRef.current.value;
        currentEntityForEdit.last_name = inputLastNameRef.current.value;
        currentEntityForEdit.email = inputEmailRef.current.value;
        currentEntityForEdit.phone = inputPhoneRef.current.value;
        currentEntityForEdit.gender = gender;
        currentEntityForEdit.birthDate = inputBirthDateRef.current.value;
        

        console.log(currentEntityForEdit);

        ApiSavePatient(loggedUser.token, currentEntityForEdit, data => {
            setCurrentEntityForEdit(data);
            setMustRefresh(mustRefresh + 1);
        });
    }

    return (

        <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
                <Typography sx={{ mt: 4, mb: 0 }} variant="h6" component="div">
                    {currentEntityForEdit._id ? "Edit Patient" : "Add Patient"}
                </Typography>


            </Grid>

            <Grid item xs={12} md={4}>
                <TextField label="First Name" inputRef={inputFirstNameRef} required fullWidth autoFocus />
            </Grid>
            <Grid item xs={0} md={8}></Grid>

            <Grid item xs={12} md={4}>
                <TextField label="Last Name" inputRef={inputLastNameRef} required fullWidth />
            </Grid>
            <Grid item xs={0} md={8}></Grid>

            <Grid item xs={12} md={4}>
                <TextField label="Email" inputRef={inputEmailRef} required fullWidth />
            </Grid>
            <Grid item xs={0} md={8}></Grid>

            <Grid item xs={12} md={4}>
                <TextField label="Phone" inputRef={inputPhoneRef} required fullWidth />
            </Grid>
            <Grid item xs={0} md={8}></Grid>

            <Grid item xs={12} md={4}>
                <FormControl fullWidth required>
                    <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                    <Select label="Gender" value={gender} onChange={handleChange}>
                        <MenuItem value={"Male"}>Male</MenuItem>
                        <MenuItem value={"Female"}>Female</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={0} md={8}></Grid>

            <Grid item xs={12} md={4}>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'en-gb'}>
                    <DatePicker inputRef={inputBirthDateRef} defaultValue={moment(Date.parse(currentEntityForEdit.birthDate))}  label="Birth Date" />
                </LocalizationProvider>
            </Grid>

            <Grid item xs={12} md={12}>
                <Button variant="outlined" color="error" onClick={() => setCurrentEntityForEdit(null)} style={{ marginRight: "10px" }}>
                    CLOSE
                </Button>
                <Button variant="outlined" color="primary" onClick={() => savePatient()}>
                    SAVE
                </Button>
            </Grid>
        </Grid>
    );
}

export default PatientEdit;
