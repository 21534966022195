import { useEffect, useState, useContext } from 'react';
import * as React from 'react';
import { GlobalContext } from "../../contexts/GlobalContext";

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FolderIcon from '@mui/icons-material/Folder';

import IosShareIcon from '@mui/icons-material/IosShare';

import Button from '@mui/material/Button';

const GenesEdit = () => {
    const { currentGene, setCurrentGene } = useContext(GlobalContext);

    function generate(element) {
        return [0, 1, 2].map((value) =>
            React.cloneElement(element, {
                key: value,
            }),
        );
    }

    const Demo = styled('div')(({ theme }) => ({
        backgroundColor: theme.palette.background.paper,
    }));

    return (

        <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
                <Typography sx={{ mt: 4, mb: 0 }} variant="h6" component="div">
                    {currentGene.symbol}
                </Typography>

                <Typography sx={{ mt: 0, mb: 0 }} variant="h8" component="div">
                    {currentGene.name}
                </Typography>

                <Typography sx={{ mt: 2, mb: 0 }} variant="h8" component="div">
                    Location:<b>{currentGene.location}</b>
                </Typography>

                <Typography sx={{ mt: 0, mb: 0 }} variant="h8" component="div">
                    Group:<b>{currentGene.group.name}</b>
                </Typography>

                <Typography sx={{ mt: 0, mb: 0 }} variant="h8" component="div">
                    Type:<b>{currentGene.locus_type}</b>
                </Typography>
            </Grid>
            <Grid item xs={4} md={4}>
                <List dense={true}>
                    <ListItem
                        secondaryAction={
                            (currentGene.hgnc_id && <IconButton edge="start" aria-label="delete">
                                <a target='blank' href={'https://www.genenames.org/data/gene-symbol-report/#!/hgnc_id/' + currentGene.hgnc_id}>
                                    <IosShareIcon />
                                </a>
                            </IconButton>)
                        }>

                        <ListItemText
                            primary="HUGO Gene Nomenclature Committee"
                            secondary={'https://www.genenames.org/data/gene-symbol-report/#!/hgnc_id/' + currentGene.hgnc_id} />
                    </ListItem>

                </List>

                <List dense={true}>
                    <ListItem
                        secondaryAction={
                            (currentGene.entrez_id && <IconButton edge="start" aria-label="delete">
                                <a target='blank' href={'https://www.ncbi.nlm.nih.gov/gene/' + currentGene.entrez_id}>
                                    <IosShareIcon />
                                </a>
                            </IconButton>)
                        }>

                        <ListItemText
                            primary="National Center of Biotechnology Information"
                            secondary={'https://www.ncbi.nlm.nih.gov/gene/' + currentGene.entrez_id} />
                    </ListItem>
                </List>

                <List dense={true}>
                    <ListItem
                        secondaryAction={
                            (currentGene.ensembl_id && <IconButton edge="start" aria-label="delete">
                                <a target='blank' href={'https://www.ensembl.org/Homo_sapiens/Gene/Summary?g=' + currentGene.ensembl_id}>
                                    <IosShareIcon />
                                </a>
                            </IconButton>)
                        }>

                        <ListItemText
                            primary="Ensembl"
                            secondary={'https://www.ensembl.org/Homo_sapiens/Gene/Summary?g=' + currentGene.ensembl_id} />
                    </ListItem>
                </List>

            </Grid>

            <Grid item xs={4} md={4}>
                <List dense={true}>
                    <ListItem
                        secondaryAction={
                            (currentGene.iuphar_id && <IconButton edge="start" aria-label="delete">
                                <a target='blank' href={'https://www.guidetopharmacology.org/GRAC/ObjectDisplayForward?objectId=' + currentGene.iuphar_id}>
                                    <IosShareIcon />
                                </a>
                            </IconButton>)
                        }>

                        <ListItemText
                            primary="IUPHAR/BPS Guide to PHARMACOLOGY"
                            secondary={'https://www.guidetopharmacology.org/GRAC/ObjectDisplayForward?objectId=' + currentGene.iuphar_id} />
                    </ListItem>

                </List>

                <List dense={true}>
                    <ListItem
                        secondaryAction={
                            (currentGene.vega_id && <IconButton edge="start" aria-label="delete">
                                <a target='blank' href={'https://vega.archive.ensembl.org/Homo_sapiens/Gene/Summary?db=core;g=' + currentGene.vega_id}>
                                    <IosShareIcon />
                                </a>
                            </IconButton>)
                        }>

                        <ListItemText
                            primary="Vega"
                            secondary={'https://vega.archive.ensembl.org/Homo_sapiens/Gene/Summary?db=core;g=' + currentGene.vega_id} />
                    </ListItem>
                </List>

                <List dense={true}>
                    <ListItem
                        secondaryAction={
                            (currentGene.omim_id && <IconButton edge="start" aria-label="delete">
                                <a target='blank' href={'https://omim.org/entry/' + currentGene.omim_id}>
                                    <IosShareIcon />
                                </a>
                            </IconButton>)
                        }>

                        <ListItemText
                            primary="Omim"
                            secondary={'https://omim.org/entry/' + currentGene.omim_id} />
                    </ListItem>
                </List>

            </Grid>

            <Grid item xs={4} md={4}>
                <List dense={true}>
                    <ListItem
                        secondaryAction={
                            (currentGene.enzyme_id && <IconButton edge="start" aria-label="delete">
                                <a target='blank' href={'https://enzyme.expasy.org/EC/' + currentGene.enzyme_id}>
                                    <IosShareIcon />
                                </a>
                            </IconButton>)
                        }>

                        <ListItemText
                            primary="Enzyme nomenclature database"
                            secondary={'https://enzyme.expasy.org/EC/' + currentGene.enzyme_id} />
                    </ListItem>

                </List>

                <List dense={true}>
                    <ListItem
                        secondaryAction={
                            (currentGene.horde_id && <IconButton edge="start" aria-label="delete">
                                <a target='blank' href={'http://genome.weizmann.ac.il/horde/card/index/symbol:' + currentGene.horde_id}>
                                    <IosShareIcon />
                                </a>
                            </IconButton>)
                        }>

                        <ListItemText
                            primary="Horde"
                            secondary={'http://genome.weizmann.ac.il/horde/card/index/symbol:' + currentGene.horde_id} />
                    </ListItem>
                </List>

                <List dense={true}>
                    <ListItem
                        secondaryAction={
                            (currentGene.ena_id && <IconButton edge="start" aria-label="delete">
                                <a target='blank' href={'https://www.ebi.ac.uk/ena/browser/view/' + currentGene.ena_id}>
                                    <IosShareIcon />
                                </a>
                            </IconButton>)
                        }>

                        <ListItemText
                            primary="European Nucleotide Archive"
                            secondary={'https://www.ebi.ac.uk/ena/browser/view/' + currentGene.ena_id} />
                    </ListItem>
                </List>

            </Grid>

            <Button variant="outlined" color="error"  onClick={()=> setCurrentGene(null)}>
                CLOSE
            </Button>

        </Grid>

    );
}

export default GenesEdit;