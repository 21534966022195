import { useEffect, useState, useContext } from 'react';
import { GlobalContext } from "../../contexts/GlobalContext";

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';


import PatientsEdit from "./PatientsEdit"
import { ApiGetPatients } from '../../utils/RestServices';

const columns = [
    { id: 'first_name', label: 'First Name', minWidth: 170 },
    { id: 'last_name', label: 'Last Name', minWidth: 170 },
    { id: 'gender', label: 'Gender', minWidth: 500 },
    { id: 'email', label: 'Email', minWidth: 170 },
    { id: 'phone', label: 'Phone', minWidth: 170 },
];

export default function PatientsPage() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = useState([]);
    const { loggedUser, currentEntityForEdit, setCurrentEntityForEdit, currentDoctor } = useContext(GlobalContext);

    const [mustRefresh, setMustRefresh] = useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        setMustRefresh(mustRefresh + 1);
        setCurrentEntityForEdit(null);
    }, [])

    useEffect(() => {
        
        if (mustRefresh > 0) {
            ApiGetPatients(loggedUser.token, data => {
                setRows(data);
            });
        }
    }, [mustRefresh])

    return (
        <>
            {!currentEntityForEdit && <Paper>
                <TableContainer sx={{ maxHeight: 1440 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.format && typeof value === 'number'
                                                            ? column.format(value)
                                                            : value}
                                                    </TableCell>
                                                );
                                            })}

                                            <TableCell>
                                                <EditIcon style={{ cursor: "pointer" }} onClick={() => setCurrentEntityForEdit(row)}></EditIcon>

                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>}

            {!currentEntityForEdit && currentDoctor && <Fab color="primary" aria-label="add" style={{ position: "absolute", right: "10px", bottom: "10px" }} onClick={() => setCurrentEntityForEdit({})}>
                <AddIcon />
            </Fab>}

            {currentEntityForEdit && <PatientsEdit mustRefresh={mustRefresh} setMustRefresh={setMustRefresh}></PatientsEdit>}
        </>
    );
}
