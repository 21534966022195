import { useEffect, useRef, useContext, useState } from 'react';
import * as React from 'react';
import { GlobalContext } from "../../contexts/GlobalContext";

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ApiSaveInstitution } from '../../utils/RestServices';
import 'moment/locale/en-gb';
import Button from '@mui/material/Button';
import moment from 'moment';

const InstitutionsEdit = ({setMustRefresh, mustRefresh}) => {
    const { currentEntityForEdit, setCurrentEntityForEdit, loggedUser } = useContext(GlobalContext);
    const inputNameRef = useRef();
    const inputStreetRef = useRef();
    const inputCityRef = useRef();
    const inputCountryRef = useRef();
    const inputPostcodeRef = useRef();

    const [gender, setGender] = useState("Male");

    const handleChange = (event) => {
        setGender(event.target.value);
      };
    

    useEffect(() => {
        if (currentEntityForEdit._id) {
            inputNameRef.current.value = currentEntityForEdit.name;
            inputStreetRef.current.value = currentEntityForEdit.street;
            inputCityRef.current.value = currentEntityForEdit.city;
            inputCountryRef.current.value = currentEntityForEdit.country;
            inputPostcodeRef.current.value = currentEntityForEdit.postcode;
        }
    }, [])

    const savePatient = () => {
        currentEntityForEdit.name = inputNameRef.current.value;
        currentEntityForEdit.street = inputStreetRef.current.value;
        currentEntityForEdit.city = inputCityRef.current.value;
        currentEntityForEdit.country = inputCountryRef.current.value;
        currentEntityForEdit.postcode = inputPostcodeRef.current.value;
        

        console.log(currentEntityForEdit);

        ApiSaveInstitution(loggedUser.token, currentEntityForEdit, data => {
            setCurrentEntityForEdit(data);
            setMustRefresh(mustRefresh + 1);
        });
    }

    return (

        <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
                <Typography sx={{ mt: 4, mb: 0 }} variant="h6" component="div">
                    {currentEntityForEdit._id ? "Edit" : "Add"} Institution
                </Typography>


            </Grid>

            <Grid item xs={12} md={4}>
                <TextField label="Name" inputRef={inputNameRef} required fullWidth autoFocus />
            </Grid>
            <Grid item xs={12} md={8}></Grid>

            <Grid item xs={12} md={4}>
                <TextField label="Street" inputRef={inputStreetRef} required fullWidth />
            </Grid>
            <Grid item xs={12} md={8}></Grid>

            <Grid item xs={12} md={4}>
                <TextField label="City" inputRef={inputCityRef} required fullWidth />
            </Grid>
            <Grid item xs={12} md={8}></Grid>

            <Grid item xs={12} md={4}>
                <TextField label="Country" inputRef={inputCountryRef} required fullWidth />
            </Grid>
            <Grid item xs={12} md={8}></Grid>

            <Grid item xs={12} md={4}>
                <TextField id="postcode" label="Postcode" inputRef={inputPostcodeRef} required fullWidth />
            </Grid>
            <Grid item xs={12} md={8}></Grid>

            

            <Grid item xs={12} md={12}>
                <Button variant="outlined" color="error" onClick={() => setCurrentEntityForEdit(null)} style={{ marginRight: "10px" }}>
                    CLOSE
                </Button>
                <Button variant="outlined" color="primary" onClick={() => savePatient()}>
                    SAVE
                </Button>
            </Grid>
        </Grid>
    );
}

export default InstitutionsEdit;
