import {useEffect, useState, useContext} from 'react';
import { GlobalContext } from "../../contexts/GlobalContext";

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';


import GenesEdit from "./GenesEdit"
import { ApiGetGenes } from '../../utils/RestServices';

const columns = [
    { id: 'symbol', label: 'Symbol', minWidth: 170 },
    { id: 'name', label: 'Name', minWidth: 500 },
    { id: 'group', label: 'Group', minWidth: 300 },
    { id: 'locus_type', label: 'Type', minWidth: 300 },
    { id: 'location', label: 'Location', minWidth: 150 },
    // {
    //     id: 'group',
    //     label: 'Population',
    //     minWidth: 170,
    //     align: 'right',
    //     format: (group) => group.name,
    // },
    // {
    //     id: 'population',
    //     label: 'Population',
    //     minWidth: 170,
    //     align: 'right',
    //     format: (value) => value.toLocaleString('en-US'),
    // }
];






export default function GenesPage() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = useState([]);
    const { loggedUser, currentGene, setCurrentGene } = useContext(GlobalContext);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        ApiGetGenes(loggedUser.token, data => {
            setRows(data);
        });
        setCurrentGene(null);
    }, [])

    return (
        <>
        {!currentGene && <Paper>
            <TableContainer sx={{ maxHeight: 1440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                                        {columns.map((column) => {
                                            const value = (column.id == "group")?row["group"]["name"]:row[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {column.format && typeof value === 'number'
                                                        ? column.format(value)
                                                        : value}
                                                </TableCell>
                                            );
                                        })}

                                                <TableCell>
                                                    <EditIcon style={{cursor:"pointer"}}  onClick={()=> setCurrentGene(row)}></EditIcon>
                                                    
                                                </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>}

        {currentGene && <GenesEdit></GenesEdit>}
        </>
    );
}