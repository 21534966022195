import { useEffect, useRef, useContext, useState } from 'react';
import * as React from 'react';
import { GlobalContext } from "../../contexts/GlobalContext";

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { ApiSaveAdministrator } from '../../utils/RestServices';
import 'moment/locale/en-gb';
import Button from '@mui/material/Button';

const AdministratorsEdit = ({ setMustRefresh, mustRefresh }) => {
    const { currentEntityForEdit, setCurrentEntityForEdit, loggedUser } = useContext(GlobalContext);
    const inputFirstNameRef = useRef();
    const inputLastNameRef = useRef();
    const inputEmailRef = useRef();
    const inputPasswordRef = useRef();




    useEffect(() => {
        if (currentEntityForEdit._id) {
            inputFirstNameRef.current.value = currentEntityForEdit.first_name;
            inputLastNameRef.current.value = currentEntityForEdit.last_name;
            inputEmailRef.current.value = currentEntityForEdit.email;
        }
    }, [])

    const savePatient = () => {
        currentEntityForEdit.first_name = inputFirstNameRef.current.value;
        currentEntityForEdit.last_name = inputLastNameRef.current.value;
        currentEntityForEdit.email = inputEmailRef.current.value;
        if (inputPasswordRef.current) {
            currentEntityForEdit.password = inputPasswordRef.current.value;
        }

        console.log(currentEntityForEdit);

        ApiSaveAdministrator(loggedUser.token, currentEntityForEdit, data => {
            setCurrentEntityForEdit(data);
            setMustRefresh(mustRefresh + 1);
        });
    }

    return (

        <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
                <Typography sx={{ mt: 4, mb: 0 }} variant="h6" component="div">
                    {currentEntityForEdit._id ? "Edit" : "Add"} Administrator
                </Typography>


            </Grid>


            <Grid item xs={12} md={4}>
                <TextField label="First Name" inputRef={inputFirstNameRef} required fullWidth autoFocus />
            </Grid>
            <Grid item xs={12} md={8}></Grid>


            <Grid item xs={12} md={4}>
                <TextField label="Last Name" inputRef={inputLastNameRef} required fullWidth />
            </Grid>
            <Grid item xs={12} md={8}></Grid>

            <Grid item xs={12} md={4}>
                <TextField label="Email" inputRef={inputEmailRef} required fullWidth />
            </Grid>
            <Grid item xs={12} md={8}></Grid>


            <Grid item xs={12} md={4}>
                <TextField label="Password" type="password" inputRef={inputPasswordRef} fullWidth />
            </Grid>
            <Grid item xs={12} md={8}></Grid>






            <Grid item xs={12} md={12}>
                <Button variant="outlined" color="error" onClick={() => setCurrentEntityForEdit(null)} style={{ marginRight: "10px" }}>
                    CLOSE
                </Button>
                <Button variant="outlined" color="primary" onClick={() => savePatient()}>
                    SAVE
                </Button>
            </Grid>
        </Grid>
    );
}

export default AdministratorsEdit;
