import { useRef, useEffect, useContext } from 'react';
import { GlobalContext } from "./contexts/GlobalContext";
import LoginComponent from './components/LoginComponent';
import MainWindowComponent from './components/MainWindowComponent';

import InfoMessageComponent from './components/InfoMessageComponent';

function App() {
    const windowRef = useRef();

    const { logged, showInfoMessage} = useContext(GlobalContext);

    useEffect(() => {
        console.log(process.env.REACT_APP_CMS_URL);
        // windowRef.current.style.transformOrigin = 'top left';
        // windowRef.current.style.transform = `scale(${window.innerWidth / process.env.REACT_APP_WIDTH}, ${window.innerHeight / process.env.REACT_APP_HEIGHT})`;      

        const handler = e => e.preventDefault()
        const handlerDrop = e => {
            e.preventDefault()
            e.dataTransfer.effectAllowed = "none";
            e.dataTransfer.dropEffect = "none";
        }

        document.addEventListener('gesturestart', handler)
        document.addEventListener('gesturechange', handler)
        document.addEventListener('gestureend', handler)
        window.addEventListener('dragover', handlerDrop)
        window.addEventListener('drop', handlerDrop)
        window.addEventListener('dragenter', handlerDrop)

        window.addEventListener('error', e => {
            if (e.message === 'ResizeObserver loop limit exceeded') {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                );
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        });

        return () => {
            document.removeEventListener('gesturestart', handler)
            document.removeEventListener('gesturechange', handler)
            document.removeEventListener('gestureend', handler)
            window.removeEventListener('dragover', handlerDrop)
            window.removeEventListener('drop', handlerDrop)
            window.removeEventListener('dragenter', handlerDrop)
        }
    }, [])

    return (
        <div ref={windowRef} style={{ display: "flex", flexDirection: "row" }}>
            {!logged && <LoginComponent />}

            {logged && <MainWindowComponent />}

            {showInfoMessage != '' && <InfoMessageComponent/>}
        </div>
    );
}
export default App;
