import { useEffect, useRef, useContext, useState } from 'react';
import * as React from 'react';
import { GlobalContext } from "../../contexts/GlobalContext";

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { ApiSaveAnalyze, ApiGetPatients } from '../../utils/RestServices';
import 'moment/locale/en-gb';
import Button from '@mui/material/Button';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import moment from 'moment';


const AdministratorsEdit = ({ setMustRefresh, mustRefresh }) => {
    const { currentEntityForEdit, setCurrentEntityForEdit, loggedUser, currentDoctor } = useContext(GlobalContext);

    const inputDateRef = useRef();

    const [patient, setPatient] = useState("");
    const [patients, setPatients] = useState([]);


    const handleChange = (event) => {
        setPatient(event.target.value);
    };


    useEffect(() => {
        if (currentEntityForEdit._id) {
            setPatient(currentEntityForEdit.patient._id);
        }

        ApiGetPatients(loggedUser.token, data => {
            setPatients(data);
        });
    }, [])

    const savePatient = () => {
        currentEntityForEdit.date = inputDateRef.current.value;
        currentEntityForEdit.patient = patient;        
        currentEntityForEdit.doctor = currentDoctor._id;        
        
        ApiSaveAnalyze(loggedUser.token, currentEntityForEdit, data => {
            setCurrentEntityForEdit(data);
            setMustRefresh(mustRefresh + 1);
        });
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
                <Typography sx={{ mt: 4, mb: 0 }} variant="h6" component="div">
                    {currentEntityForEdit._id ? "Edit" : "Add"} Analyze
                </Typography>
            </Grid>

            <Grid item xs={12} md={4}>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'en-gb'}>
                    <DatePicker inputRef={inputDateRef} defaultValue={currentEntityForEdit._id?moment(Date.parse(currentEntityForEdit.date)):moment(Date.now())}  label="Date" />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={8}></Grid>

            <Grid item xs={12} md={4}>
                <FormControl fullWidth required>
                    <InputLabel id="demo-simple-select-label">Choose Patient</InputLabel>
                    <Select label="Choose Patient" value={patient} onChange={handleChange}>

                        {patients.map((pat) => <MenuItem value={pat._id}>{pat.first_name} {pat.last_name}</MenuItem>)}


                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={8}></Grid>






            <Grid item xs={12} md={12}>
                <Button variant="outlined" color="error" onClick={() => setCurrentEntityForEdit(null)} style={{ marginRight: "10px" }}>
                    CLOSE
                </Button>
                <Button variant="outlined" color="primary" onClick={() => savePatient()}>
                    SAVE
                </Button>
            </Grid>
        </Grid>
    );
}

export default AdministratorsEdit;
