import { useRef, useContext, useEffect } from 'react';

import { GlobalContext } from "../contexts/GlobalContext";


import styles from "./LoginComponent.module.css";

import InputText from "./commons/InputText";
import ButtonComp from "./commons/ButtonComp";

import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';

import { ApiLoginUser } from '../utils/RestServices';

import GenomeBackground from "../graphics/genome.jpg";




import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';


const LoginComponent = () => {

    const inputUserNameRef = useRef()
    const inputPasswordRef = useRef()

    const { setShowInfoMessage, setInfoMessage, setLoggedUser, setLogged, setCurrentDoctor } = useContext(GlobalContext);


    useEffect(() => {
        // console.log(inputUserNameRef.current);
        inputUserNameRef.current.value = "@admin.tedra";
        inputPasswordRef.current.value = "";
    }, [])

    function login() {
        var body = JSON.stringify({
            email: inputUserNameRef.current.value,
            password: inputPasswordRef.current.value

        },);

        console.log("apas login");

        ApiLoginUser(body, (withError, data) => {
            console.log(withError);
            if (withError) {
                setShowInfoMessage('error');
                setInfoMessage(data);
            } else {
                setLoggedUser(data);

                if (data.role == "DOCTOR") {
                    setCurrentDoctor(data);
                } else {
                    setCurrentDoctor(null);
                }

                setLogged(true);
            }
        });
    }



    return (

        <div className={styles.component}>

            <div className={styles.left_screen}>
                <img src={GenomeBackground} className={styles.circles}></img>
            </div>
            <div className={styles.right_screen}>
                <div className={styles.login_card}>
                    <Container component="main" maxWidth="xs">
                        <CssBaseline />
                        <Box
                            sx={{
                                marginTop: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                <LockOutlinedIcon />
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                Sign in
                            </Typography>
                            <Box component="form" noValidate sx={{ mt: 1 }}>
                                <TextField
                                    margin="normal"
                                    inputRef={inputUserNameRef}
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                />
                                <TextField
                                    margin="normal"
                                    inputRef={inputPasswordRef}
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                />

                                <Button onClick={() => login()} fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                                    Sign In
                                </Button>

                            </Box>
                        </Box>

                    </Container>
                </div>
            </div>






        </div>

    );
}

export default LoginComponent;