import React, { useState } from "react"

export const GlobalContext = React.createContext([{}, () => { }])

export const GlobalProvider = (props) => {
    const [logged, setLogged] = useState(false);
    const [loggedUser, setLoggedUser] = useState(null);
    const [currentDoctor, setCurrentDoctor] = useState(null);
    const [showInfoMessage, setShowInfoMessage] = useState(false);
    const [infoMessage, setInfoMessage] = useState("");

    const [currentEntityForEdit, setCurrentEntityForEdit] = useState(null);
    const [currentGene, setCurrentGene] = useState(null);

    return (
        <GlobalContext.Provider value={{
            logged, setLogged,
            loggedUser, setLoggedUser,
            showInfoMessage, setShowInfoMessage,
            infoMessage, setInfoMessage,
            currentEntityForEdit, setCurrentEntityForEdit,
            currentDoctor, setCurrentDoctor,
            currentGene, setCurrentGene
        }}>
            {props.children}
        </GlobalContext.Provider>
    )
}