import { useEffect, useState, useContext } from 'react';
import { GlobalContext } from "../../contexts/GlobalContext";

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import moment from 'moment';


import AnalysisEdit from "./AnalysisEdit"
import { ApiGetAnalysis } from '../../utils/RestServices';

const columns = [
    { id: 'date', label: 'Date', minWidth: 170 },
    { id: 'patient', label: 'Patient', minWidth: 170 },
    { id: 'doctor', label: 'Doctor', minWidth: 170 }
];

export default function PatientsPage() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = useState([]);
    const { loggedUser, currentEntityForEdit, setCurrentEntityForEdit, currentDoctor } = useContext(GlobalContext);

    const [mustRefresh, setMustRefresh] = useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        setMustRefresh(mustRefresh + 1);
        setCurrentEntityForEdit(null);
    }, [])

    useEffect(() => {
        if (mustRefresh > 0) {
            console.log("ApiGetAnalysis");            

            ApiGetAnalysis(loggedUser.token, currentDoctor?currentDoctor._id:null, data => {
                console.log(data);
                setRows(data);
            });
        }
    }, [mustRefresh])

    return (
        <>
            {!currentEntityForEdit && <Paper>
                <TableContainer sx={{ maxHeight: 1440 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                                            {columns.map((column) => {
                                                var value = "";

                                                if (column.id == "patient") {
                                                    value = row["patient"]["first_name"] + " " + row["patient"]["last_name"];
                                                } else if (column.id == "doctor") {
                                                    value = row["doctor"]["first_name"] + " " + row["doctor"]["last_name"];
                                                } else if (column.id == "date") {
                                                    value = moment(Date.parse(row[column.id])).format("DD/MM/YYYY");
                                                } else {                                                    
                                                    value = row[column.id];
                                                }                                                
                                                
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.format && typeof value === 'number'
                                                            ? column.format(value)
                                                            : value}
                                                    </TableCell>
                                                );
                                            })}

                                            <TableCell>
                                                <EditIcon style={{ cursor: "pointer" }} onClick={() => setCurrentEntityForEdit(row)}></EditIcon>

                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>}

            {!currentEntityForEdit && currentDoctor && <Fab color="primary" aria-label="add" style={{ position: "absolute", right: "10px", bottom: "10px" }} onClick={() => setCurrentEntityForEdit({})}>
                <AddIcon />
            </Fab>}

            {currentEntityForEdit && <AnalysisEdit mustRefresh={mustRefresh} setMustRefresh={setMustRefresh}></AnalysisEdit>}
        </>
    );
}
