export function ApiLoginUser(body, callback) {
    console.log("body");
    console.log(body);
    const requestOptions = {
        method: 'POST',
        //mode: 'cors',
        headers: { 'Content-Type': 'application/json'},
        body: body
    };

    let status = null;

    fetch(process.env.REACT_APP_CMS_URL + '/api/login', requestOptions)
        .then(response => {
            status = response.status;
            return response.json()
        })
        .then(data => {
            if (status != 200) {
                callback?.(true, data);
            } else {
                callback?.(false, data);
            }
        })
        .catch(err => {
            callback?.(true, err.message);
        })
}

export function ApiLogoutUser(token, callback) {
    const requestOptions = {
        method: 'GET',
        headers: { 'token': token }
    };


    fetch(process.env.REACT_APP_CMS_URL + '/api/logout', requestOptions)
        .then(response => response.json())
        .then(data => {
            callback?.(data);
        });
}

export function ApiGetGenes(token, callback) {
    const requestOptions = {
        method: 'GET',
        headers: { 'token': token }
    };


    fetch(process.env.REACT_APP_CMS_URL + '/api/getGenes', requestOptions)
        .then(response => response.json())
        .then(data => {
            callback?.(data);
        });
}

export function ApiGetGeneGroups(token, callback) {
    const requestOptions = {
        method: 'GET',
        headers: { 'token': token }
    };


    fetch(process.env.REACT_APP_CMS_URL + '/api/getGeneGroups', requestOptions)
        .then(response => response.json())
        .then(data => {
            callback?.(data);
        });
}

export function ApiGetPatients(token, callback) {
    const requestOptions = {
        method: 'GET',
        headers: { 'token': token }
    };

    fetch(process.env.REACT_APP_CMS_URL + '/api/getPatients', requestOptions)
        .then(response => response.json())
        .then(data => {
            callback?.(data);
        });
}

export function ApiSavePatient(token, body, callback) {
    var string_body = JSON.stringify(body);
    const requestOptions = {
        method: 'POST',
        headers: { 'token': token, 'Content-Type': 'application/json' },
        body: string_body
    };

    fetch(process.env.REACT_APP_CMS_URL + '/api/savePatient', requestOptions)
        .then(response => response.json())
        .then(data => {
            callback?.(data);
        });
}


export function ApiGetAdministrators(token, callback) {
    var string_body = JSON.stringify({role: "ADMINISTRATOR"});
    const requestOptions = {
        method: 'POST',
        headers: { 'token': token, 'Content-Type': 'application/json' },
        body: string_body

    };

    fetch(process.env.REACT_APP_CMS_URL + '/api/getUsers', requestOptions)
        .then(response => response.json())
        .then(data => {
            callback?.(data);
        });
}

export function ApiSaveAdministrator(token, body, callback) {
    body.role = "ADMINISTRATOR";
    var body_string = JSON.stringify(body);
    const requestOptions = {
        method: 'POST',
        headers: { 'token': token, 'Content-Type': 'application/json' },
        body: body_string
    };

    console.log("asdasd1");
    fetch(process.env.REACT_APP_CMS_URL + '/api/saveUser', requestOptions)
        .then(response => response.json())
        .then(data => {
            console.log("123");
            console.log(data);
            callback?.(data);
        });
}

export function ApiGetInstitutions(token, callback) {
    const requestOptions = {
        method: 'GET',
        headers: { 'token': token }
    };

    fetch(process.env.REACT_APP_CMS_URL + '/api/getInstitutions', requestOptions)
        .then(response => response.json())
        .then(data => {
            callback?.(data);
        });
}

export function ApiSaveInstitution(token, body, callback) {
    var string_body = JSON.stringify(body);
    const requestOptions = {
        method: 'POST',
        headers: { 'token': token, 'Content-Type': 'application/json' },
        body: string_body
    };

    fetch(process.env.REACT_APP_CMS_URL + '/api/saveInstitution', requestOptions)
        .then(response => response.json())
        .then(data => {
            callback?.(data);
        });
}

export function ApiGetDoctors(token, callback) {
    console.log("asdasd 3");
    var string_body = JSON.stringify({role: "DOCTOR"});
    const requestOptions = {
        method: 'POST',
        headers: { 'token': token, 'Content-Type': 'application/json' },
        body: string_body
    };

    console.log("asdasd1");
    fetch(process.env.REACT_APP_CMS_URL + '/api/getUsers', requestOptions)
        .then(response => response.json())
        .then(data => {
            console.log("123");
            console.log(data);
            callback?.(data);
        });
}

export function ApiSaveDoctor(token, body, callback) {
    body.role = "DOCTOR";
    var body_string = JSON.stringify(body);
    const requestOptions = {
        method: 'POST',
        headers: { 'token': token, 'Content-Type': 'application/json' },
        body: body_string
    };

    console.log("asdasd1");
    fetch(process.env.REACT_APP_CMS_URL + '/api/saveUser', requestOptions)
        .then(response => response.json())
        .then(data => {
            console.log("123");
            console.log(data);
            callback?.(data);
        });
}

export function ApiGetAnalysis(token, doctor_id, callback) {
    console.log(doctor_id);
    var string_body = JSON.stringify({doctor_id: doctor_id});
    const requestOptions = {
        method: 'POST',
        headers: { 'token': token, 'Content-Type': 'application/json' },
        body: string_body
    };

    fetch(process.env.REACT_APP_CMS_URL + '/api/getAnalysis', requestOptions)
        .then(response => response.json())
        .then(data => {
            console.log("123");
            console.log(data);
            callback?.(data);
        });
}

export function ApiSaveAnalyze(token, body, callback) {
    var string_body = JSON.stringify(body);
    const requestOptions = {
        method: 'POST',
        headers: { 'token': token, 'Content-Type': 'application/json' },
        body: string_body
    };

    fetch(process.env.REACT_APP_CMS_URL + '/api/saveAnalyze', requestOptions)
        .then(response => response.json())
        .then(data => {
            callback?.(data);
        });
}


