import { useContext, useState, useEffect, useRef } from 'react';
import { GlobalContext } from "../contexts/GlobalContext"
import style from "./InfoMessageComponent.module.css";

const InfoMessageComponent = () => {
    const { infoMessage, setShowInfoMessage, showInfoMessage } = useContext(GlobalContext);
    const ref = useRef();


    useEffect(() => {
        if(showInfoMessage === 'error'){
            ref.current.style.backgroundColor = 'red';
        }else{
            ref.current.style.backgroundColor = 'green';
        }
    }, [showInfoMessage])

    useEffect(() => {
        setTimeout(() => {
            ref.current.style.top = "0px";
        }, 10);
        
        setTimeout(() => {
            ref.current.style.top = "-100px";
            setTimeout(() => {                
                setShowInfoMessage('');
            }, 500);

        }, 2000);
    }, [])

    return (
        <div className={style.component} ref={ref}>
            {infoMessage}
        </div>
    );
}



export default InfoMessageComponent;