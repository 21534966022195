import { useState, useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import AirIcon from '@mui/icons-material/Air';

import GroupIcon from '@mui/icons-material/Group';

import GenesPage from "./pages/GenesPage"
import GeneGroupsPage from "./pages/GeneGroupsPage"
import AdministratorsPage from "./pages/AdministratorsPage"

import PatientsPage from "./pages/PatientsPage"
import AccessibilityIcon from '@mui/icons-material/Accessibility';


import InstitutionsPage from "./pages/InstitutionsPage"
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

import DoctorsPage from "./pages/DoctorsPage"
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

import AnalysisPage from "./pages/AnalysisPage"
import ScienceIcon from '@mui/icons-material/Science';

import { GlobalContext } from "../contexts/GlobalContext";

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';


const drawerWidth = 240;



const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function MainWindowComponent() {
    const [open, setOpen] = useState(true);
    const [currentPage, setCurrentPage] = useState("Patients");
    const { loggedUser, setLogged, currentDoctor } = useContext(GlobalContext);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleProfileMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleProfileClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        setLogged(false);
    };



    const CreateListItem = (name, icon) => {
        return (
            <ListItem className='active' disablePadding sx={{ display: 'block' }}>
                <ListItemButton style={{ background: currentPage == name ? "#EFEFEF" : "transparent" }} onClick={() => setCurrentPage(name)} sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5 }}>
                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                        {icon}
                    </ListItemIcon>
                    <ListItemText primary={name} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
            </ListItem>
        )
    };

    return (
        <Box sx={{ display: 'flex', flexGrow: 1 }} >
            <CssBaseline />
            <AppBar position="fixed" elevation={4}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => { setOpen(!open) }}
                        edge="start"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Tedra Genome
                    </Typography>

                    <div>
                        <IconButton
                            size="small"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleProfileMenu}
                            color="inherit"
                        >
                            <ListItemText primary={loggedUser.first_name + " " + loggedUser.last_name} secondary={loggedUser?.role} />
                            
      
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleProfileClose}
                        >
                            <MenuItem onClick={handleLogout}>
                                <ListItemIcon>
                                    <LogoutIcon fontSize="small" />
                                </ListItemIcon>
                                Logout
                            </MenuItem>

                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader />
                <Divider />
                <List>
                    {CreateListItem("Gene Groups", <Diversity2Icon />)}
                    {CreateListItem("Genes", <AirIcon />)}
                </List>
                <Divider />
                <List>
                    {CreateListItem("Patients", <AccessibilityIcon />)}
                    {CreateListItem("Analysis", <ScienceIcon />)}
                </List>

                {!currentDoctor && <>
                    <Divider />
                    <List>
                        {CreateListItem("Institutions", <AccountBalanceIcon />)}
                        {CreateListItem("Doctors", <AssignmentIndIcon />)}


                    </List>
                    <Divider />
                    <List>
                        {CreateListItem("Administrators", <GroupIcon />)}
                    </List>
                </>}
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />
                {currentPage == "Genes" && <GenesPage></GenesPage>}
                {currentPage == "Administrators" && <AdministratorsPage></AdministratorsPage>}
                {currentPage == "Patients" && <PatientsPage></PatientsPage>}
                {currentPage == "Gene Groups" && <GeneGroupsPage></GeneGroupsPage>}
                {currentPage == "Institutions" && <InstitutionsPage></InstitutionsPage>}
                {currentPage == "Doctors" && <DoctorsPage></DoctorsPage>}
                {currentPage == "Analysis" && <AnalysisPage></AnalysisPage>}


            </Box>
        </Box>
    );
}
